<script setup lang="ts">
const props = withDefaults(defineProps<{
  is?: string | Component
  position?: 'left' | 'right'
  size?: 'sm' | 'md'
}>(), {
  is: 'div',
  position: 'right',
  size: 'md',
})

const positionClass = computed(() => {
  return props.position === 'left' ? 'left-0' : 'right-0'
})
const sizeClass = computed(() => {
  return props.size === 'sm' ? 'w-96' : 'w-[480px]'
})
</script>

<template>
  <component
    :is="props.is"
    class="fixed top-0 z-[250] h-full max-w-full overflow-hidden bg-white dark:bg-muted-800"
    :class="[
      positionClass,
      sizeClass,
    ]"
  >
    <slot />
  </component>
</template>
